<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
    created: function () {
        let self = this
        this.axios.interceptors.response.use(undefined, function (err) {
            return new Promise(() => {
                if (err.response.status === 400 || err.response.status === 401) {
                    if (self.$store.getters['auth/isLoggedIn']) {
                        self.$store.dispatch('auth/logout')
                    }
                }
                throw err
            })
        })

        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        if (token && user) {
            let data = {
                user_info: JSON.parse(user),
                token_info: JSON.parse(token)
            }
            this.$store.commit('auth/auth_success', data)
        }
    },
    methods: {
        setVh () {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    },
    mounted () {
        this.setVh()
        window.addEventListener('resize', this.setVh);
        history.replaceState(null, null, ' ');
    }
}
</script>

<style lang="scss">
  @import './assets/styles/reset.css';
  @import './assets/styles/vars.scss';

  @import './assets/styles/fonts.css';

  @import './assets/styles/mixins.scss';

  @import './assets/styles/layout.scss';

  @import './assets/styles/header.scss';

  @import './assets/styles/parts.scss';

  @import './assets/styles/carousel.scss';
  @import './assets/styles/main.scss';
  @import './assets/styles/overlay.scss';
  @import './assets/styles/login.scss';
  @import './assets/styles/page-not-found.scss';
  @import './assets/styles/footer.scss';

  @import './assets/styles/adaptation.css';
</style>