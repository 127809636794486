<template>
    <div class="progress">

        <strong class="progress-title" v-if="!is_activated">Активируй партнерское соглашение</strong>
        <strong class="progress-title" v-else>Проходи курс Prof.Первые шаги!</strong>

        <div class="start" v-if="!is_activated">Быстрый<br/>старт!</div>
        <div class="button" :class="{_hide: is_activated}" @click="$emit('showPopup')"><div>Активация</div></div>

        <div class="pin" v-if="step > 0" :class="{_show: show_step > 1}" step="1"></div>
        <div class="pin" v-if="step > 1" :class="{_show: show_step > 2}" step="2"></div>
        <div class="pin" v-if="step > 2" :class="{_show: show_step > 3}" step="3"></div>
        <div class="pin" v-if="step > 3" :class="{_show: show_step > 4}" step="4"></div>

        <div class="progress-status" v-if="!is_activated">
            <div class="points">
                <strong>{{ GW_lo }} / 25.0 PV</strong>
            </div>
            <div class="progress-line">
                <div :style="progressStyle"></div>
            </div>
            <div class="days">
                <strong><span>Осталось </span>{{ $store.state.auth.user.gw_days }} {{ getNoun($store.state.auth.user.gw_days, 'день', 'дня', 'дней') }}</strong>
                <div>для активации соглашения</div>
            </div>
        </div>

        <svg version="1.1" id="progress-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 854 292" style="enable-background:new 0 0 854 292;" xml:space="preserve">

            <path class="line_fill" d="M34,230.9c8.9-21.3,34.9-57.6,75.6-57.6c29.2,0,51.8,11.8,72.4,27.1"/>
            <path class="line_fill anim-line line1" :class="{done: show_step > 0}" d="M182,200.4C161.39999999999998,185.10000000000002 138.79999999999998,173.3 109.6,173.3C68.9,173.3 42.9,209.6 34,230.9"/>

            <path class="line_default" v-if="step < 1" d="M182,200.5c38,28.3,68.9,68.5,121.1,68.5c10.8,0,20.4-2.9,29.1-8"/>
            <path class="line_default anim-line line2" :class="{done: show_step > 1}" v-if="step < 1" d="M332.20000000000005,261C323.5,266.1 313.90000000000003,269 303.1,269C250.9,269 220,228.8 182,200.5"/>
            <path class="line_fill" v-if="step > 0" d="M182,200.5c38,28.3,68.9,68.5,121.1,68.5c10.8,0,20.4-2.9,29.1-8"/>
            <path class="line_fill anim-line line2" :class="{done: show_step > 1}" v-if="step > 0" d="M332.20000000000005,261C323.5,266.1 313.90000000000003,269 303.1,269C250.9,269 220,228.8 182,200.5"/>

            <path class="line_default" v-if="step < 2" d="M332.1,261c56.1-32.7,76.1-154.3,154.9-153"/>
            <path class="line_default anim-line line3" :class="{done: show_step > 2}" v-if="step < 2" d="M487,108C408.20000000000005,106.69999999999999 388.20000000000005,228.3 332.1,261"/>
            <path class="line_fill" v-if="step > 1" d="M332.1,261c56.1-32.7,76.1-154.3,154.9-153"/>
            <path class="line_fill anim-line line3" :class="{done: show_step > 2}" v-if="step > 1" d="M487,108C408.20000000000005,106.69999999999999 388.20000000000005,228.3 332.1,261"/>

            <path class="line_default" v-if="step < 3" d="M631,268.7c-1.5,0.2-3,0.3-4.5,0.3c-68.8,0-57-162-139.5-160.5"/>
            <path class="line_default anim-line line4" :class="{done: show_step > 3}" v-if="step < 3" d="M631,268.7c-1.5,0.2-3,0.3-4.5,0.3c-68.8,0-57-162-139.5-160.5"/>
            <path class="line_fill" v-if="step > 2" d="M631,268.7c-1.5,0.2-3,0.3-4.5,0.3c-68.8,0-57-162-139.5-160.5"/>
            <path class="line_fill anim-line line4" :class="{done: show_step > 3}" v-if="step > 2" d="M631,268.7c-1.5,0.2-3,0.3-4.5,0.3c-68.8,0-57-162-139.5-160.5"/>

            <path class="line_default" v-if="step < 4" d="M771.5,124.5c-73.4,0-85.2,137.1-140.5,144.2"/>
            <path class="line_default anim-line line5" :class="{done: show_step > 4}" v-if="step < 4" d="M771.5,124.5c-73.4,0-85.2,137.1-140.5,144.2"/>
            <path class="line_fill" v-if="step > 3" d="M771.5,124.5c-73.4,0-85.2,137.1-140.5,144.2"/>
            <path class="line_fill anim-line line5" :class="{done: show_step > 4}" v-if="step > 3" d="M771.5,124.5c-73.4,0-85.2,137.1-140.5,144.2"/>

            <circle class="round_limit" cx="33" cy="230" r="11.5"/>

            <circle class="round_fill" cx="33" cy="230" r="5"/>

            <circle v-show="show_step > 1" v-if="step < 1" class="round_empty" cx="182" cy="201" r="5.5"/>
            <circle v-show="show_step > 1" v-if="step > 0" class="round_fill" cx="182" cy="201" r="6"/>

            <circle v-show="show_step > 2" v-if="step < 2" class="round_empty" cx="332" cy="261" r="5.5"/>
            <circle v-show="show_step > 2" v-if="step > 1" class="round_fill" cx="332" cy="261" r="6"/>

            <circle v-show="show_step > 3" v-if="step < 3" class="round_empty" cx="481" cy="108" r="5.5"/>
            <circle v-show="show_step > 3" v-if="step > 2" class="round_fill" cx="481" cy="108" r="6"/>

            <circle v-show="show_step > 4" v-if="step < 4" class="round_empty" cx="631" cy="267" r="5.5"/>
            <circle v-show="show_step > 4" v-if="step > 3" class="round_fill" cx="631" cy="267" r="6"/>

            <circle v-if="is_activated" class="round_limit" cx="780" cy="124" r="11.5"/>
            <circle v-else class="round_limit" cx="792" cy="124" r="9.5"/>

            <circle v-show="show_step > 5" v-if="step < 5 && is_activated" class="round_empty" cx="780" cy="124" r="5"/>
            <circle v-show="show_step > 5" v-if="step > 4 && is_activated" class="round_fill" cx="780" cy="124" r="5"/>

            <path class="rocket" d="M49.7,144.5c-0.1-0.6-0.6-1-1.2-1.2c-7.2-1.4-16.3,1.7-24.1,8.1c-2-1-4.3-1.3-6.6-1c-2.4,0.3-4.6,1.5-6.4,3.2
                L2,163.1c-0.4,0.4-0.5,1-0.3,1.5c0.2,0.5,0.7,0.9,1.2,1l9.6,0.9c-0.2,0.4-0.3,0.8-0.5,1.1c-0.8,1.8-0.4,3.8,1,5.3l7.1,7.1
                c0.9,0.9,2.1,1.4,3.3,1.4c0.6,0,1.3-0.1,1.9-0.4c0.4-0.2,0.8-0.3,1.1-0.5l0.9,9.6c0.1,0.6,0.4,1,1,1.2c0.2,0.1,0.3,0.1,0.5,0.1
                c0.4,0,0.8-0.1,1-0.4l9.5-9.5c3.4-3.4,4.3-8.7,2.2-13C48,160.8,51.1,151.6,49.7,144.5L49.7,144.5z M29.3,175.6l-11.9-11.9
                c1.6-2.5,3.5-4.9,5.8-7.2c3.8-3.8,8-6.7,12.3-8.5l9.5,9.5c-1.4,3.4-3.6,6.8-6.3,10c-0.7,0.8-1.4,1.6-2.2,2.3
                C34.2,172.1,31.8,174,29.3,175.6L29.3,175.6z M44.9,145.9c0.7,0,1.4,0,2.1,0.1c0.3,2.6,0,5.5-0.9,8.5l-7.6-7.6
                C40.7,146.3,42.8,145.9,44.9,145.9z M6.3,162.9l7.3-7.3c2.2-2.2,5.5-3,8.4-2.1c-0.3,0.3-0.6,0.6-0.9,0.9c-2.8,2.8-5.2,6-7.1,9.2
                L6.3,162.9z M24.2,178.3c-0.7,0.3-1.5,0.2-2-0.4l-7.1-7.1c-0.6-0.6-0.7-1.3-0.4-2c0.3-0.8,0.7-1.6,1.2-2.4l10.8,10.8
                C25.9,177.6,25,178,24.2,178.3z M37.3,179.5l-7.3,7.3l-0.7-7.7c3.3-1.9,6.4-4.3,9.2-7.1c0.3-0.3,0.6-0.6,0.9-0.9
                C40.3,174,39.6,177.2,37.3,179.5L37.3,179.5z"/>
            <path class="rocket" d="M35.7,157.3c-1.9-1.9-4.9-1.9-6.8,0c-1.9,1.9-1.9,4.9,0,6.8c0.9,0.9,2.1,1.4,3.4,1.4c1.3,0,2.5-0.5,3.4-1.4
                C37.5,162.2,37.5,159.2,35.7,157.3z M33.6,162c-0.4,0.3-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.7-0.7-0.7-1.9,0-2.6
                c0.4-0.4,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5C34.3,160.1,34.3,161.3,33.6,162z"/>
            <path class="rocket" d="M14.3,178.7c-0.6-0.6-1.5-0.6-2.1,0L0.4,190.5c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1,0.4s0.7-0.1,1-0.4
                l11.8-11.8C14.9,180.2,14.9,179.3,14.3,178.7z"/>
            <path class="rocket" d="M5.6,180.1l4-4c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0l-4,4c-0.6,0.6-0.6,1.5,0,2.1
                c0.3,0.3,0.7,0.4,1,0.4C5,180.5,5.4,180.3,5.6,180.1z"/>
            <path class="rocket" d="M16.9,183.4l-4,4c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4l4-4c0.6-0.6,0.6-1.5,0-2.1
                C18.4,182.8,17.5,182.8,16.9,183.4L16.9,183.4z"/>
            <path class="gray" d="M617.2,186c-4,0-8.1,0-12.2,0c-1.3,0-2.2-1-2.1-2.2c0.1-0.9,0.8-1.5,1.7-1.6c0.3,0,0.6,0,0.8,0
                c7.5,0,15.1,0,22.6,0c2.1,0,4-0.5,5.7-1.8c4.5-3.4,4.3-10.3-0.2-13.5c-1.5-1.1-3.2-1.6-5.1-1.6c-0.3,0-0.5,0-0.8,0
                c-1.6,0-2.3-0.7-2.4-2.2c-0.2-2.5-1.1-4.7-2.6-6.7c-2.3-3-5.4-4.8-9.2-5.3c-3.7-0.5-7.2,0.3-10.2,2.5c-3.1,2.3-4.9,5.5-5.4,9.4
                c-0.2,1.6-0.8,2.3-2.5,2.3c-2.2-0.1-4.2,0.4-6,1.7c-3.8,2.8-4.5,8.7-1.3,12.2c1.8,2,4.1,3,6.8,3c1.3,0,2.6,0,3.9,0
                c0.8,0,1.3,0.5,1.6,1.2c0.3,0.7,0.2,1.4-0.3,1.9c-0.4,0.3-0.9,0.7-1.3,0.7c-2.1,0-4.1,0-6.2-0.2c-2.1-0.2-4.1-1.2-5.7-2.5
                c-3.4-2.8-5.1-6.5-4.6-10.9c0.6-6,5.3-10.4,11.4-11c0.5,0,0.6-0.2,0.7-0.7c1.1-4.9,3.9-8.7,8.2-11.3c3.4-2.1,7.3-2.8,11.2-2.3
                c4.6,0.5,8.3,2.6,11.3,6c1.9,2.2,3.2,4.7,3.8,7.5c0.1,0.5,0.3,0.7,0.8,0.7c5.6,0.5,10.1,4.4,11.2,9.8c0.9,4.8-0.6,8.8-4.4,12
                c-2.3,1.9-5,2.8-8,2.8C624.6,186,620.9,186,617.2,186z"/>
            <path class="gray" d="M724.2,291c-4,0-8.1,0-12.2,0c-1.3,0-2.2-1-2.1-2.2c0.1-0.9,0.8-1.5,1.7-1.6c0.3,0,0.6,0,0.8,0
                c7.5,0,15.1,0,22.6,0c2.1,0,4-0.5,5.7-1.8c4.5-3.4,4.3-10.3-0.2-13.5c-1.5-1.1-3.2-1.6-5.1-1.6c-0.3,0-0.5,0-0.8,0
                c-1.6,0-2.3-0.7-2.4-2.2c-0.2-2.5-1.1-4.7-2.6-6.7c-2.3-3-5.4-4.8-9.2-5.3c-3.7-0.5-7.2,0.3-10.2,2.5c-3.1,2.3-4.9,5.5-5.4,9.4
                c-0.2,1.6-0.8,2.3-2.5,2.3c-2.2-0.1-4.2,0.4-6,1.7c-3.8,2.8-4.5,8.7-1.3,12.2c1.8,2,4.1,3,6.8,3c1.3,0,2.6,0,3.9,0
                c0.8,0,1.3,0.5,1.6,1.2c0.3,0.7,0.2,1.4-0.3,1.9c-0.4,0.3-0.9,0.7-1.3,0.7c-2.1,0-4.1,0-6.2-0.2c-2.1-0.2-4.1-1.2-5.7-2.5
                c-3.4-2.8-5.1-6.5-4.6-10.9c0.6-6,5.3-10.4,11.4-11c0.5,0,0.6-0.2,0.7-0.7c1.1-4.9,3.9-8.7,8.2-11.3c3.4-2.1,7.3-2.8,11.2-2.3
                c4.6,0.5,8.3,2.6,11.3,6c1.9,2.2,3.2,4.7,3.8,7.5c0.1,0.5,0.3,0.7,0.8,0.7c5.6,0.5,10.1,4.4,11.2,9.8c0.9,4.8-0.6,8.8-4.4,12
                c-2.3,1.9-5,2.8-8,2.8C731.6,291,727.9,291,724.2,291z"/>
            <path class="gray" d="M327.2,151c-4,0-8.1,0-12.2,0c-1.3,0-2.2-1-2.1-2.2c0.1-0.9,0.8-1.5,1.7-1.6c0.3,0,0.6,0,0.8,0
                c7.5,0,15.1,0,22.6,0c2.1,0,4-0.5,5.7-1.8c4.5-3.4,4.3-10.3-0.2-13.5c-1.5-1.1-3.2-1.6-5.1-1.6c-0.3,0-0.5,0-0.8,0
                c-1.6,0-2.3-0.7-2.4-2.2c-0.2-2.5-1.1-4.7-2.6-6.7c-2.3-3-5.4-4.8-9.2-5.3c-3.7-0.5-7.2,0.3-10.2,2.5c-3.1,2.3-4.9,5.5-5.4,9.4
                c-0.2,1.6-0.8,2.3-2.5,2.3c-2.2-0.1-4.2,0.4-6,1.7c-3.8,2.8-4.5,8.7-1.3,12.2c1.8,2,4.1,3,6.8,3c1.3,0,2.6,0,3.9,0
                c0.8,0,1.3,0.5,1.6,1.2c0.3,0.7,0.2,1.4-0.3,1.9c-0.4,0.3-0.9,0.7-1.3,0.7c-2.1,0-4.1,0-6.2-0.2c-2.1-0.2-4.1-1.2-5.7-2.5
                c-3.4-2.8-5.1-6.5-4.6-10.9c0.6-6,5.3-10.4,11.4-11c0.5,0,0.6-0.2,0.7-0.7c1.1-4.9,3.9-8.7,8.2-11.3c3.5-2.1,7.3-2.8,11.2-2.3
                c4.6,0.5,8.3,2.6,11.3,6c1.9,2.2,3.2,4.7,3.8,7.5c0.1,0.5,0.3,0.7,0.8,0.7c5.6,0.5,10.1,4.4,11.2,9.8c0.9,4.8-0.6,8.8-4.4,12
                c-2.3,1.9-5,2.8-8,2.8C334.6,151,330.9,151,327.2,151z"/>
            <path class="gray" d="M158.2,286c-4,0-8.1,0-12.2,0c-1.3,0-2.2-1-2.1-2.2c0.1-0.9,0.8-1.5,1.7-1.6c0.3,0,0.6,0,0.8,0
                c7.5,0,15.1,0,22.6,0c2.1,0,4-0.5,5.7-1.8c4.5-3.4,4.3-10.3-0.2-13.5c-1.5-1.1-3.2-1.6-5.1-1.6c-0.3,0-0.5,0-0.8,0
                c-1.6,0-2.3-0.7-2.4-2.2c-0.2-2.5-1.1-4.7-2.6-6.7c-2.3-3-5.4-4.8-9.2-5.3c-3.7-0.5-7.2,0.3-10.2,2.5c-3.1,2.3-4.9,5.5-5.4,9.4
                c-0.2,1.6-0.8,2.3-2.5,2.3c-2.2-0.1-4.2,0.4-6,1.7c-3.8,2.8-4.5,8.7-1.3,12.2c1.8,2,4.1,3,6.8,3c1.3,0,2.6,0,3.9,0
                c0.8,0,1.3,0.5,1.6,1.2c0.3,0.7,0.2,1.4-0.3,1.9c-0.4,0.3-0.9,0.7-1.3,0.7c-2.1,0-4.1,0-6.2-0.2c-2.1-0.2-4.1-1.2-5.7-2.5
                c-3.4-2.8-5.1-6.5-4.6-10.9c0.6-6,5.3-10.4,11.4-11c0.5,0,0.6-0.2,0.7-0.7c1.1-4.9,3.9-8.7,8.2-11.3c3.4-2.1,7.3-2.8,11.2-2.3
                c4.6,0.5,8.3,2.6,11.3,6c1.9,2.2,3.2,4.7,3.8,7.5c0.1,0.5,0.3,0.7,0.8,0.7c5.6,0.5,10.1,4.4,11.2,9.8c0.9,4.8-0.6,8.8-4.4,12
                c-2.3,1.9-5,2.8-8,2.8C165.6,286,161.9,286,158.2,286z"/>
            <path v-if="!is_activated" class="prize" d="M837.2,70.9h-12.8c-1.5-0.1-2.9,1.1-2.9,2.6v2.6c0.1,1.5,1.4,2.7,2.9,2.6h12.8c1.5,0.1,2.9-1.1,2.9-2.6v-2.6
                C840,72,838.7,70.8,837.2,70.9L837.2,70.9z M837.2,76.1h-12.8c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0l0-2.5c0.1-0.1,0.2-0.1,0.3-0.1h12.8
                c0.2,0,0.3,0.1,0.3,0l0,2.5C837.4,76.1,837.3,76.1,837.2,76.1L837.2,76.1z"/>
            <path v-if="!is_activated" class="prize" d="M817.4,59.2c-2.2,0-4,1.7-4,3.9v2.6c-3,0-5.3,2.3-5.4,5.2v10.4c0,1.4,1.2,2.6,2.7,2.6h40.1
                c1.5,0,2.7-1.2,2.7-2.6V70.9c0-2.9-2.4-5.2-5.4-5.2v-2.6c0-2.1-1.8-3.9-4-3.9h-1.9c-2.8-4.5-4.6-10.7-5.5-18.2l3.4,1.7
                c0.9,0.5,2,0.4,2.8-0.2c0.8-0.6,1.2-1.6,1.1-2.5l-1.8-10.2l7.6-7.2c0.7-0.7,1-1.7,0.7-2.7c-0.3-0.9-1.2-1.6-2.2-1.8l-10.5-1.5
                l-4.7-9.3c-0.5-0.9-1.4-1.4-2.4-1.4c-1,0-1.9,0.6-2.4,1.4l-4.7,9.3l-10.5,1.5c-1,0.1-1.8,0.8-2.2,1.8s-0.1,2,0.7,2.7l7.6,7.2
                L817.4,40c-0.2,1,0.2,2,1.1,2.5c0.8,0.6,1.9,0.7,2.8,0.2l3.5-1.8c-0.9,7.5-2.8,13.8-5.5,18.3H817.4z M850.8,70.9l0,10.4h-40.1V70.9
                c0-1.4,1.2-2.6,2.7-2.6h34.8C849.6,68.3,850.8,69.5,850.8,70.9z M845.5,63.1v2.6H816v-2.6c0-0.7,0.6-1.3,1.3-1.3h26.8
                C844.9,61.8,845.5,62.4,845.5,63.1z M820,40.4l1.8-10.2c0.1-0.8-0.1-1.7-0.8-2.3l-7.6-7.2l10.5-1.5c0.9-0.1,1.6-0.7,2-1.4l4.7-9.3
                l0,0l4.7,9.3c0.4,0.8,1.1,1.3,2,1.4l10.5,1.5l-7.6,7.2c-0.6,0.6-0.9,1.5-0.8,2.3l1.8,10.2l-9.4-4.8c-0.8-0.4-1.7-0.4-2.5,0L820,40.4
                z M827.6,39.5l3.1-1.6l3.2,1.6c0.8,8,2.6,14.6,5.3,19.7h-16.8C825.1,54.1,826.9,47.5,827.6,39.5L827.6,39.5z"/>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="505" width="48" height="37"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                </clipPath>
                <g>
                    <path class="gray" d="M546.3,20.1c0.3,1,0.7,1.8,0.8,2.8c0.4,3.1-0.7,5.5-3.2,7.2c-4.2,2.8-10.2,0.4-11.2-4.8
                        c-0.1-0.4-0.1-1-0.1-1.4c0-1,0.7-1.7,1.5-1.8c1-0.1,1.7,0.6,2,1.6c0.1,0.4,0.1,1,0.3,1.4c0.6,1.8,2.4,2.8,4.2,2.6
                        c1.8-0.3,3.2-2,3.1-3.8c-0.1-2-1.4-3.5-3.3-3.7c-0.3,0-0.6,0-1,0c-9.6,0-19.3,0-28.9,0c-0.7,0-1.3-0.1-1.7-0.7
                        c-0.4-0.6-0.6-1.1-0.3-1.8c0.3-0.7,0.8-1,1.5-1.1c0.1,0,0.3,0,0.6,0c11.6,0,23.2,0,34.7,0c1.5,0,2.8-0.7,3.5-2.1
                        c0.7-1.4,0.6-2.8-0.4-4.1c-1-1.3-2.2-1.7-3.8-1.3c-1.5,0.4-2.5,1.4-2.8,3c0,0.3,0,0.4-0.1,0.7c0,1-0.8,1.7-1.8,1.7
                        c-1,0-1.7-0.9-1.7-1.8c0-4.4,3.9-7.9,8.1-7.4c5.7,0.7,8.4,7.1,5,11.8c-1.1,1.6-2.7,2.6-4.6,2.8C546.6,20.1,546.4,20.1,546.3,20.1z
                        "/>
                    <path class="gray" d="M518.7,13.9c-3.9,0-7.8,0-11.6,0c-0.7,0-1.4-0.1-1.7-0.7c-0.8-1.1-0.1-2.7,1.3-2.8c0.1,0,0.4,0,0.6,0
                        c7.5,0,15.1,0,22.6,0c1.4,0,2.5-0.6,3.2-1.8c0.7-1.4,0.3-3.3-1.1-4.3c-1.4-1-3.2-0.7-4.3,0.6c-0.6,0.6-0.7,1.3-0.8,2
                        c0,0.7-0.3,1.4-1,1.7c-1.3,0.7-2.7-0.3-2.7-1.6c0-2.4,1.1-4.4,3.1-5.7c2.4-1.6,5-1.6,7.4,0c2.4,1.6,3.3,3.8,3.1,6.5
                        c-0.3,3.4-3.1,6.1-6.4,6.1C526.3,13.9,522.4,13.9,518.7,13.9z"/>
                    <path class="gray" d="M519.2,23c1.7,0,3.3,0,4.9,0c3.6,0,6.6,2.8,6.8,6.5c0.3,3.5-2.1,6.9-6.1,7.5c-4,0.4-7.1-2.6-7.5-6.1
                        c-0.1-0.7-0.1-1.4,0.3-2c0.4-0.6,1.1-0.9,1.8-0.6c0.7,0.3,1.3,0.7,1.3,1.4c0.1,0.4,0.1,0.9,0.3,1.4c0.4,1.6,2,2.6,3.5,2.3
                        c2-0.3,2.9-1.7,3.1-3.1c0.1-1.7-1-3.3-2.5-3.7c-0.4-0.1-0.8-0.1-1.1-0.1c-3.2,0-6.6,0-9.8,0c-1.3,0-2-0.9-2-2
                        c0.1-0.9,0.8-1.6,1.8-1.6C515.6,23,517.4,23,519.2,23z"/>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_3_" x="452" y="223" width="48" height="37"/>
                </defs>
                <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
                </clipPath>
                <g>
                    <path class="gray" d="M493.3,243.1c0.3,1,0.7,1.8,0.8,2.8c0.4,3.1-0.7,5.5-3.2,7.2c-4.2,2.8-10.2,0.4-11.2-4.8
                        c-0.1-0.4-0.1-1-0.1-1.4c0-1,0.7-1.7,1.5-1.8c1-0.1,1.7,0.6,2,1.6c0.1,0.4,0.1,1,0.3,1.4c0.6,1.8,2.4,2.8,4.2,2.6
                        c1.8-0.3,3.2-2,3.1-3.8c-0.1-2-1.4-3.5-3.3-3.7c-0.3,0-0.6,0-1,0c-9.6,0-19.3,0-28.9,0c-0.7,0-1.3-0.1-1.7-0.7
                        c-0.4-0.6-0.6-1.1-0.3-1.8c0.3-0.7,0.8-1,1.5-1.1c0.1,0,0.3,0,0.6,0c11.6,0,23.2,0,34.7,0c1.5,0,2.8-0.7,3.5-2.1s0.6-2.8-0.4-4.1
                        c-1-1.3-2.2-1.7-3.8-1.3s-2.5,1.4-2.8,3c0,0.3,0,0.4-0.1,0.7c0,1-0.8,1.7-1.8,1.7s-1.7-0.9-1.7-1.8c0-4.4,3.9-7.9,8.1-7.4
                        c5.7,0.7,8.4,7.1,5,11.8c-1.1,1.6-2.7,2.6-4.6,2.8C493.6,243.1,493.4,243.1,493.3,243.1z"/>
                    <path class="gray" d="M465.7,236.9c-3.9,0-7.8,0-11.6,0c-0.7,0-1.4-0.1-1.7-0.7c-0.8-1.1-0.1-2.7,1.3-2.8c0.1,0,0.4,0,0.6,0
                        c7.5,0,15.1,0,22.6,0c1.4,0,2.5-0.6,3.2-1.8c0.7-1.4,0.3-3.3-1.1-4.3c-1.4-1-3.2-0.7-4.3,0.6c-0.6,0.6-0.7,1.3-0.8,2
                        c0,0.7-0.3,1.4-1,1.7c-1.3,0.7-2.7-0.3-2.7-1.6c0-2.4,1.1-4.4,3.1-5.7c2.4-1.6,5-1.6,7.4,0c2.4,1.6,3.3,3.8,3.1,6.5
                        c-0.3,3.4-3.1,6.1-6.4,6.1C473.3,236.9,469.4,236.9,465.7,236.9z"/>
                    <path class="gray" d="M466.2,246c1.7,0,3.3,0,4.9,0c3.6,0,6.6,2.8,6.8,6.5c0.3,3.5-2.1,6.9-6.1,7.5c-4,0.4-7.1-2.6-7.5-6.1
                        c-0.1-0.7-0.1-1.4,0.3-2c0.4-0.6,1.1-0.9,1.8-0.6c0.7,0.3,1.3,0.7,1.3,1.4c0.1,0.4,0.1,0.9,0.3,1.4c0.4,1.6,2,2.6,3.5,2.3
                        c2-0.3,2.9-1.7,3.1-3.1c0.1-1.7-1-3.3-2.5-3.7c-0.4-0.1-0.8-0.1-1.1-0.1c-3.2,0-6.6,0-9.8,0c-1.3,0-2-0.9-2-2
                        c0.1-0.9,0.8-1.6,1.8-1.6C462.6,246,464.4,246,466.2,246z"/>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_5_" x="696" y="57" width="48" height="37"/>
                </defs>
                <clipPath id="SVGID_6_">
                    <use xlink:href="#SVGID_5_"  style="overflow:visible;"/>
                </clipPath>
                <g>
                    <path class="gray" d="M737.3,77.1c0.3,1,0.7,1.8,0.8,2.8c0.4,3.1-0.7,5.5-3.2,7.2c-4.2,2.8-10.2,0.4-11.2-4.8
                        c-0.1-0.4-0.1-1-0.1-1.4c0-1,0.7-1.7,1.5-1.8c1-0.1,1.7,0.6,2,1.6c0.1,0.4,0.1,1,0.3,1.4c0.6,1.8,2.4,2.8,4.2,2.6
                        c1.8-0.3,3.2-2,3.1-3.8c-0.1-2-1.4-3.5-3.3-3.7c-0.3,0-0.6,0-1,0c-9.6,0-19.3,0-28.9,0c-0.7,0-1.3-0.1-1.7-0.7
                        c-0.4-0.6-0.6-1.1-0.3-1.8c0.3-0.7,0.8-1,1.5-1.1c0.1,0,0.3,0,0.6,0c11.6,0,23.2,0,34.7,0c1.5,0,2.8-0.7,3.5-2.1
                        c0.7-1.4,0.6-2.8-0.4-4.1c-1-1.3-2.2-1.7-3.8-1.3c-1.5,0.4-2.5,1.4-2.8,3c0,0.3,0,0.4-0.1,0.7c0,1-0.8,1.7-1.8,1.7
                        c-1,0-1.7-0.9-1.7-1.8c0-4.4,3.9-7.9,8.1-7.4c5.7,0.7,8.4,7.1,5,11.8c-1.1,1.6-2.7,2.6-4.6,2.8C737.6,77.1,737.4,77.1,737.3,77.1z
                        "/>
                    <path class="gray" d="M709.7,70.9c-3.9,0-7.8,0-11.6,0c-0.7,0-1.4-0.1-1.7-0.7c-0.8-1.1-0.1-2.7,1.3-2.8c0.1,0,0.4,0,0.6,0
                        c7.5,0,15.1,0,22.6,0c1.4,0,2.5-0.6,3.2-1.8c0.7-1.4,0.3-3.3-1.1-4.3c-1.4-1-3.2-0.7-4.3,0.6c-0.6,0.6-0.7,1.3-0.8,2
                        c0,0.7-0.3,1.4-1,1.7c-1.3,0.7-2.7-0.3-2.7-1.6c0-2.4,1.1-4.4,3.1-5.7c2.4-1.6,5-1.6,7.4,0c2.4,1.6,3.3,3.8,3.1,6.5
                        c-0.3,3.4-3.1,6.1-6.4,6.1C717.3,70.9,713.4,70.9,709.7,70.9z"/>
                    <path class="gray" d="M710.2,80c1.7,0,3.3,0,4.9,0c3.6,0,6.6,2.8,6.8,6.5c0.3,3.5-2.1,6.9-6.1,7.5c-4,0.4-7.1-2.6-7.5-6.1
                        c-0.1-0.7-0.1-1.4,0.3-2c0.4-0.6,1.1-0.9,1.8-0.6s1.3,0.7,1.3,1.4c0.1,0.4,0.1,0.9,0.3,1.4c0.4,1.6,2,2.6,3.5,2.3
                        c2-0.3,2.9-1.7,3.1-3.1c0.1-1.7-1-3.3-2.5-3.7c-0.4-0.1-0.8-0.1-1.1-0.1c-3.2,0-6.6,0-9.8,0c-1.3,0-2-0.9-2-2
                        c0.1-0.9,0.8-1.6,1.8-1.6C706.6,80,708.4,80,710.2,80z"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        props: ['step'],
        data () {
            return {
                show_step: 0
            }
        },
        computed: {
            is_activated () {
                return this.$store.getters['auth/userActivated']
            },
            GW_lo () {
                return parseFloat(this.$store.state.auth.user.gw_lo).toFixed(1)
            },
            progressStyle () {
                return {
                    width: parseFloat(this.$store.state.auth.user.gw_lo) / 25 * 100 + '%'
                }
            }
        },
        methods: {
            getNoun(number, one, two, five) {
                let n = Math.abs(number);
                n %= 100;
                if (n >= 5 && n <= 20) {
                    return five;
                }
                n %= 10;
                if (n === 1) {
                    return one;
                }
                if (n >= 2 && n <= 4) {
                    return two;
                }
                return five;
            },
            showSteps () {
                let self = this
                self.show_step = 1
                setTimeout(() => {
                    self.show_step = 2
                    setTimeout(() => {
                        self.show_step = 3
                        setTimeout(() => {
                            self.show_step = 4
                            setTimeout(() => {
                                self.show_step = 5
                                setTimeout(() => {
                                    self.show_step = 6
                                }, 1000)
                            }, 1000)
                        }, 1000)
                    }, 1000)
                }, 1000)
            }
        },
        mounted () {
            this.showSteps()
        }
    }
</script>

<style lang="scss">
    .line_default {
        fill:none;
        stroke:#C2C2C2;
        stroke-width:3;
        stroke-linecap:round;
        stroke-linejoin:round;
        stroke-dasharray:3,6;
    }
    .line_fill {
        fill:none;
        stroke:#9CD116;
        stroke-width:3;
    }

    .anim-line {
        stroke-dasharray: 1000;
        stroke-miterlimit: 10;
        stroke-width:5;
        stroke:white;
        &.line1 {
            stroke-dashoffset: 820;
            &.done {
                stroke-dashoffset: 1000;
                animation: dash1 1s linear alternate;
            }
        }
        &.line2 {
            stroke-dashoffset: 827;
            &.done {
                stroke-dashoffset: 1000;
                animation: dash2 1s linear alternate;
            }
        }
        &.line3 {
            stroke-dashoffset: 772;
            &.done {
                stroke-dashoffset: 1000;
                animation: dash3 1s linear alternate;
            }
        }
        &.line4 {
            stroke-dashoffset: 768;
            &.done {
                stroke-dashoffset: 1000;
                animation: dash4 1s linear alternate;
            }
        }
        &.line5 {
            stroke-dashoffset: 785;
            &.done {
                stroke-dashoffset: 1000;
                animation: dash5 1s linear alternate;
            }
        }
    }

    @keyframes dash1 {
        from {stroke-dashoffset: 820;}
        to {stroke-dashoffset: 1000;}
    }
    @keyframes dash2 {
        from {stroke-dashoffset: 827;}
        to {stroke-dashoffset: 1000;}
    }
    @keyframes dash3 {
        from {stroke-dashoffset: 772;}
        to {stroke-dashoffset: 1000;}
    }
    @keyframes dash4 {
        from {stroke-dashoffset: 768;}
        to {stroke-dashoffset: 1000;}
    }
    @keyframes dash5 {
        from {stroke-dashoffset: 785;}
        to {stroke-dashoffset: 1000;}
    }

    .round_limit {
        fill:#FFFFFF;stroke:#9CD116;stroke-width:5;
    }

    .round_empty {
        fill: #FFFFFF;
        stroke: #C2C2C2;
        stroke-width: 3;
    }
    .round_fill {
        fill: #85BD5E;
    }
    .rocket{
        fill:#87C719;
    }
    .gray{fill:#EFEEEE;}
    .prize{fill:#9CD116;}

    .path1{clip-path:url(#SVGID_2_);}
    .path2{clip-path:url(#SVGID_4_);}
    .path3{clip-path:url(#SVGID_6_);}
</style>