<template>
    <div class="header" :class="{menu_open: menu_open}">
        <div class="wrapper">
            <div class="slog">Научить сетевому нельзя, зато можно сетевому научиться</div>
            <div class="inline mob-col" halign="between" valign="bottom">
                <router-link to="/" class="logo">
                    <img class="logo-image" src="@/assets/images/logo-image.svg" />
                    <img class="logo-text" src="@/assets/images/logo-text.svg" />
                </router-link>
                <div class="header-right">
                    <img class="burger" src="@/assets/images/burger.svg" @click="toggleMobMenu()" />
                    <img class="burger _close" src="@/assets/images/close-burger.svg" @click="toggleMobMenu()" />

                    <div class="user" v-if="$store.state.auth.user">
                        <strong>{{ $store.state.auth.user.gw_first_name }} {{ $store.state.auth.user.gw_last_name }}</strong>
                        <span>{{ $store.state.auth.user.gw_number }}</span>
                        <div class="user-popup-wrapper">
                            <div class="user-popup">
                                <a v-if="getEnv() == 'production'" href="https://mygreenway.com/my/" target="_blank" class="link _reverse">Профайл</a>
                                <a v-else href="http://dev1-2020.greenwaystart.com/my/" target="_blank" class="link _reverse">Профайл</a>

                                <span class="logout" @click="$store.dispatch('auth/logout')">Выйти</span>
                            </div>
                        </div>
                    </div>
                    <div class="nav inline mob-col" halign="right">
                        <router-link @click.native="sendMetrika()" to="/courses" class="courses" :class="{_enter: enter == 1, _leave: leave == 1}" @mouseenter.native="enterLink(1)" @mouseleave.native="leaveLink(1)">Курсы</router-link>
                        <router-link to="/webinars" class="webinar" :class="{_enter: enter == 2, _leave: leave == 2}" @mouseenter.native="enterLink(2)" @mouseleave.native="leaveLink(2)">Вебинары</router-link>
                        <router-link to="/trainer" class="trainer" :class="{_enter: enter == 3, _leave: leave == 3}" @mouseenter.native="enterLink(3)" @mouseleave.native="leaveLink(3)">Тренеры</router-link>
                    </div>
                </div>
            </div>
            <div class="phones">
                <a class="link" href="tel:88002345800">8-800-2345-800</a><br/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                enter: null,
                leave: null,
                menu_open: false
            }
        },
        methods: {
            getEnv () {
                let env
                if (location.href.indexOf('https://prof.mygreenway.com') != -1) {
                    env = 'production'
                } else {
                    env = 'development'
                }
                return env
            },
            sendMetrika () {
                if (this.$metrika) {
                    this.$metrika.reachGoal('menu_courses')
                }
            },
            toggleMobMenu () {
                this.menu_open = !this.menu_open
                if (this.menu_open) {
                    document.body.style.overflow = 'hidden'
                } else {
                    document.body.style.overflow = ''
                }
            },
            enterLink (id) {
                if (this.leave == id) this.leave = null
                this.enter = id
            },
            leaveLink (id) {
                this.leave = id
            }
        },
        mounted () {
            document.body.style.overflow = ''
        }
    }
</script>