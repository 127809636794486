<template>
    <div v-if="ready" class="coursesCarousel" :class="{_bg: block, _description: description, userNotActivated: $store.getters['auth/userActivated'], not_activated: not_activated, on_main: $route.name == 'main'}">
        <div v-if="description" class="description" v-html="description"></div>
        <!-- class="solo" -->

        <div v-if="not_activated" class="not-activated">
            <img src="@/assets/images/not-activated.jpg" />
            <div class="blend"></div>
            <div class="text">
                <img src="@/assets/images/lock.svg" />
                <span>Необходима <br/>активация</span>
            </div>
        </div>

        <swiper v-else ref="mySwiper" :class="{'swiper-no-swiping': (block && !isMobile)}" :options="options">
            <swiper-slide @click.native="reachGoal(item.id)" :class="{done: item.confirmed, current: item.id == $route.params.id}" v-for="(item, key) in lessons" :key="key">
                <router-link :to="'/lesson/' + item.id">
                    <img :src="item.image" />
                    <div class="text">
                        <p v-html="item.title"></p>
                        <span>{{ item.course_title }}</span>
                        <div class="cb"></div>
                    </div>
                </router-link>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

    export default {
        props: ['course', 'active', 'lessons', 'block', 'not_activated', 'description', 'page'],
        components: {
            Swiper,
            SwiperSlide
        },
        computed: {
            swiper () {
                return this.$refs.mySwiper.$swiper
            }
        },
        data () {
            return {
                ready: false,
                options: {
                    slidesPerView: 'auto',
                    freeMode: true,
                    centeredSlides: false,
                    noSwiping: this.block ? true : false
                },
                isMobile: false
            }
        },
        methods: {
            reachGoal (id) {
                if (this.$metrika) {
                    if (this.page == 'main') {
                        let name = 'main_lesson_' + id
                        this.$metrika.reachGoal(name)
                    } else if (this.page == 'courses') {
                        let name = 'courses_lesson_' + id
                        this.$metrika.reachGoal(name)
                    } else if (this.page == 'lesson') {
                        this.$metrika.reachGoal('slider_lesson')
                    }
                }
            },
            swipeTo (id) {
                this.swiper.slideTo(id, 0, false)
            },
            checkMobile () {
                this.isMobile = window.innerWidth <= 640 ? true : false
            }
        },
        mounted () {
            this.checkMobile()

            window.addEventListener("resize", this.checkMobile);
            this.ready = true

            this.$nextTick(() => {
                if (this.active) {
                    for (let i = 0; i < this.lessons.length; i++) {
                        if (this.lessons[i].id == this.active) {
                            this.swipeTo(i)
                            break
                        }
                    }
                }
            })

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.checkMobile);
        },
    }
</script>