<template>
    <div class="page">
        <div class="page-content">
            <Header></Header>
            <div class="course">
                <div v-if="status != 'loading'" class="breadcrumbs">
                    <div class="wrapper">
                        <span><router-link class="link" to="/">Главная</router-link></span>
                        <span v-html="page.title"></span>
                    </div>
                </div>

                <div class="content-wrapper" v-if="status == 'loading'">
                    <strong class="loading">Загрузка...</strong>
                </div>

                <div class="content-wrapper" v-else>
                    <h1 class="main-title" ref="main_title"><span ref="main_title_content" v-html="page.title"></span></h1>
                    <p v-html="page.description"></p>

                    <div class="lesson-content" @click="clickContent" v-html="page.content"></div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'

    import * as utils from '@/utils/auth'

    export default {
        metaInfo() {
            return {
                title: this.title,
            };
        },
        components: {
            Header,
            Footer
        },
        data () {
            return {
                title: 'Загрузка страницы...',
                status: '',
                page: false
            }
        },
        methods: {
            getData () {
                this.status = 'loading'

                let pyapi_refresh_token = utils.getCookie()

                this.axios({url: 'api/page/3?pyapi_refresh_token=' + pyapi_refresh_token, method: 'GET' })
                    .then(resp => {
                        this.status = ''
                        this.page = resp.data.page

                        this.title = this.page.title

                        let data = {
                            user_info: resp.data.user_info,
                            token_info: resp.data.token_info
                        }
                        this.$store.commit('auth/auth_success', data)

                        this.correctVideosHeight()
                        window.addEventListener("resize", this.correctVideosHeight);

                        this.correctTitleWidth()
                        window.addEventListener("resize", this.correctTitleWidth);
                    }).catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404) {
                            this.$router.push('/not-found')
                        } else {
                            this.$router.push('/courses')
                        }
                    }
                })
            },
            clickContent (e) {
                const elem = e.target;

                if (elem.href && elem.href.indexOf('#') != -1) { // its link and anchor

                    let linkBasePath = elem.href.split('#')[0]
                    let anchor = elem.href.split('#')[1]
                    let currentPath = location.origin + this.$route.path

                    if (linkBasePath == currentPath) { // same page
                        let scrollToElem = document.getElementsByName(anchor)[0]

                        window.scrollTo({
                            top: scrollToElem.offsetTop - 20,
                            behavior: "smooth"
                        })
                        e.preventDefault()
                    }
                }
            },
            correctVideosHeight() {
                this.$nextTick(() => {
                    let players = document.querySelectorAll(".player")

                    if (players.length) {
                        let width = players[0].getBoundingClientRect().width
                        let height = width / 1.7418

                        for (let player of players) {
                            player.style.height = height + 'px'
                        }
                    }
                })
            },
            correctTitleWidth() {
                this.$nextTick(() => {
                    this.$refs.main_title.style.cssText = null
                    let outerWidth = this.$refs.main_title.getBoundingClientRect().width
                    let innerWidth = this.$refs.main_title_content.getBoundingClientRect().width

                    while (innerWidth > outerWidth) {
                        let fs = parseFloat(window.getComputedStyle(this.$refs.main_title, null).getPropertyValue('font-size'))
                        let new_fs = fs - 1
                        let new_lh = new_fs * 1.125
                        this.$refs.main_title.style.fontSize = new_fs + 'px'
                        this.$refs.main_title.style.lineHeight = new_lh + 'px'

                        outerWidth = this.$refs.main_title.getBoundingClientRect().width
                        innerWidth = this.$refs.main_title_content.getBoundingClientRect().width
                    }
                })
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.correctVideosHeight);
            window.removeEventListener('resize', this.correctTitleWidth);
        },
        created () {
            this.getData()
        }
    }
</script>