import axios from 'axios'
import router from './../../router';
//import store from "../index";

import * as utils from '@/utils/auth'

const state = {
    status: '',
    //token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || '',
    token: JSON.parse(localStorage.getItem('token')) || ''
}
const getters = {
    isLoggedIn (state) {
        return !!state.token.access_token
    },
    authStatus (state) {
        return state.status
    },
    userActivated (state) {
        return state.user && state.user.gw_is_activated
    }
}
const actions = {
    logout ({ commit }) {
        return new Promise((resolve) => {
            let api_cookie = utils.getCookie(true)

            let domain
            if (location.href.indexOf('https://prof.mygreenway.com') != -1) {
                domain = '; domain=mygreenway.com'
            } else {
                domain = '; domain=greenwaystart.com'
            }

            let sendData = {
                refresh: api_cookie
            }
            axios({url: 'api/logout', data: sendData, method: 'POST' })
                .then(() => {
                    commit('logout')
                    localStorage.removeItem('user')
                    localStorage.removeItem('token')
                    delete axios.defaults.headers.common['Authorization']
                    if( api_cookie ) {
                        document.cookie = 'pyapi_refresh_token=; Max-Age=0; path=/' + domain

                    }
                    router.push('/login')
                })

            /*if (location.href.indexOf('https://prof.mygreenway.com') != -1) {
                location.href = 'https://mygreenway.com/my/?redirect=' + location.href
            } else {
                location.href = 'https://dev1-2020.greenwaystart.com/my/?redirect=' + location.href
            }*/
            resolve()
        })
    },
}
const mutations = {
    auth_request (state) {
        state.status = 'loading'
    },
    auth_success (state, data) {
        state.status = 'success'
        state.user = data.user_info
        state.token = data.token_info
        localStorage.setItem('user', JSON.stringify(state.user))
        localStorage.setItem('token', JSON.stringify(state.token))
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token.access_token

        let domain
        if (location.href.indexOf('mygreenway.com') != -1) {
            domain = '; domain=mygreenway.com'
        } else if (location.href.indexOf('greenwaystart.com') != -1) {
            domain = '; domain=greenwaystart.com'
        } else {
            domain = '; domain=localhost'
        }

        document.cookie = 'pyapi_refresh_token=' + state.token.refresh_token + domain
    },
    auth_error (state) {
        state.status = 'error'
    },
    logout (state) {
        state.status = ''
        state.user = ''
        state.token = ''
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}