<template>
    <div class="page">
        <div class="page-content">
            <Header></Header>
            <div class="main">
                <div class="content-wrapper" v-if="status == 'loading'">
                    <strong class="loading">Загрузка...</strong>
                </div>

                <div v-else>
                    <img src="@/assets/images/main-header-image.jpg" class="main-header-image" />

                    <Progress :step="count_lessons" @showPopup="show_popup = true; popup_type = 'graph'"></Progress>

                    <!--
                    <div v-else class="mainb">
                        <img class="mainb-image" src="@/assets/images/mainb.jpg" />
                        <img class="mainb-image-mobile" src="@/assets/images/mainb-mob.jpg" />
                        <div class="blend"></div>
                        <div class="mainb-popup">
                            <strong>Курс Prof.Newbie <br/>Новые уроки уже в&nbsp;мае!</strong>
                            <p>Мы&nbsp;обязательно сообщим вам, когда станет доступен следующий урок.</p>
                        </div>
                    </div>
                    -->

                    <div v-for="(item, key) in courses" :key="key">
                        <div class="content-wrapper">
                            <h2 class="list-title" v-html="item.title"></h2>
                        </div>
                        <LessonsCarousel page="main" block="true" :not_activated="checkNotActivated(item.id)" :course="item" :lessons="item.lessons" :description="item.description"></LessonsCarousel>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>

        <ActivatedPopup :type="popup_type" :show="show_popup" @closePopup="show_popup = false"></ActivatedPopup>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Progress from '@/components/Progress.vue'
    import LessonsCarousel from '@/components/LessonsCarousel.vue'
    import ActivatedPopup from '@/components/ActivatedPopup.vue'
    import Footer from '@/components/Footer.vue'

    import * as utils from '@/utils/auth'

    export default {
        metaInfo: {
            title: 'Greenway Prof.'
        },
        components: {
            Header,
            Progress,
            LessonsCarousel,
            ActivatedPopup,
            Footer
        },
        data () {
            return {
                show_popup: false,
                popup_type: false,
                status: '',
                courses: [],
                count_lessons: false
            }
        },
        methods: {
            checkNotActivated (id) {
                if (id == 1) return false
                return !this.$store.getters['auth/userActivated']
            },
            getData () {
                this.status = 'loading'

                let pyapi_refresh_token = utils.getCookie()

                this.axios({url: 'api/index?pyapi_refresh_token=' + pyapi_refresh_token, method: 'GET' })
                    .then(resp => {
                        this.status = ''

                        this.courses = resp.data.courses
                        this.count_lessons = resp.data.count_lessons

                        let data = {
                          user_info: resp.data.user_info,
                          token_info: resp.data.token_info
                        }
                        this.$store.commit('auth/auth_success', data)

                    }).catch(() => {
                        this.status = ''
                    })
            }
        },
        mounted () {
            if (this.$route.query.not_activated == 1) {
                this.popup_type = 'not_activated_webinar'
                this.show_popup = true
                this.$router.replace({'query': null});
            }
        },
        created () {
            this.getData()
        }
    }
</script>