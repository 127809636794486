import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Meta from 'vue-meta';
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(Meta);

let metrica_env
if (location.href.indexOf('https://prof.mygreenway.com') != -1) {
    metrica_env = 'production'
} else {
    metrica_env = 'development'
}

Vue.use(VueYandexMetrika, {
    id: 73743397,
    router: router,
    env: metrica_env,
    options: {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
    }
})


Vue.prototype.axios = axios
Vue.prototype.axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
