<template>
    <div class="page">
        <div class="page-content">
            <Header></Header>
            <div class="page-not-found">
                <div class="p404">
                    <img src="@/assets/images/page-not-found.png" />
                    <strong>Увы! Мы{{'\xa0'}}не{{'\xa0'}}смогли найти{{'\xa0'}}то, что{{'\xa0'}}вы{{'\xa0'}}искали :(</strong>
                    <p>Страница была удалена или введен некорректный адрес.<br/>
                        Вернитесь на{{'\xa0'}}<router-link class="link _reverse" to="/">главную страницу</router-link></p>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'

    export default {
        metaInfo: {
            title: '404'
        },
        components: {
            Header,
            Footer
        }
    }
</script>