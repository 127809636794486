<template>
    <div class="page">
        <div class="page-content">
            <Header></Header>
            <div class="course-list">
                <div class="breadcrumbs">
                    <div class="wrapper">
                        <router-link class="link" to="/">Главная</router-link>
                        <span>Вебинары</span>
                    </div>
                </div>

                <div class="content-wrapper">
                    <strong v-if="status == 'loading'" class="loading">Загрузка...</strong>

                    <h1 v-else style="margin-bottom:1rem">Вебинары</h1>
                </div>

                <div class="carousel-block" :course_id="item.id" v-for="(item, key) in courses" :key="key">
                    <LessonsCarousel page="courses" block="true" :course="item" :lessons="item.lessons" :description="item.description"></LessonsCarousel>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <ActivatedPopup :type="popup_type" :lo="popup_lo" :show="show_popup" @closePopup="show_popup = false"></ActivatedPopup>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import LessonsCarousel from '@/components/LessonsCarousel.vue'
    import ActivatedPopup from '@/components/ActivatedPopup.vue'
    import Footer from '@/components/Footer.vue'

    import * as utils from '@/utils/auth'

    export default {
        metaInfo: {
            title: 'Список вебинаров'
        },
        components: {
            Header,
            LessonsCarousel,
            ActivatedPopup,
            Footer
        },
        data () {
            return {
                status: '',
                page: {},
                courses: [],
                show_popup: false,
                popup_type: null,
                popup_lo: null
            }
        },
        methods: {
            getData () {
                this.status = 'loading'

                let pyapi_refresh_token = utils.getCookie()

                this.axios({url: 'api/courses?types=webinar&pyapi_refresh_token=' + pyapi_refresh_token, method: 'GET' })
                    .then(resp => {
                        this.status = ''

                        this.page = resp.data.page
                        this.courses = resp.data.courses

                        let data = {
                            user_info: resp.data.user_info,
                            token_info: resp.data.token_info
                        }
                        this.$store.commit('auth/auth_success', data)


                    }).catch(() => {
                    this.status = ''
                    // this.$router.push('/')
                })
            }
        },
        created () {
            this.getData()
        },
        mounted () {
            if (this.$route.query.not_activated == 1) {
                this.show_popup = true
                this.popup_type = 'not_activated_webinar'
                this.$router.replace({'query': null});
            } else if (this.$route.query.low_lo) {
                this.show_popup = true
                this.popup_type = 'webinar_low_lo'
                this.popup_lo = this.$route.query.low_lo
                this.$router.replace({'query': null});
            }
        }
    }
</script>