<template>
    <div class="footer">
        <div class="wrapper">
            <div class="inline mob-col" halign="between" valign="top">
                <div class="phones">
                    <a class="link" href="tel:88002345800">8-800-2345-800</a><br/>
                    <!--<a class="link" href="tel:83832920722">8 (383) 292-07-22</a>-->
                </div>
                <div class="contacts">
                    <!--<a class="link email" href="mailto:office@greenway.group">office@greenway.group</a>
                    <a class="link skype" href="skype:skype_8979?call">skype_8979</a>-->
                    <span>Время работы call-центра:</span>
                    <p class="time">с 6:00 до 22:00 (МСК)</p>
                </div>
                <div class="links">
                    <div class="inline social" valign="center" halign="right">
                        <!--<a href="" target="_blank">
                            <svg class="vk" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path d="M35.82 28.6268L34.5809 25.7148C33.5003 23.133 32.0736 20.9001 30.3305 19.0604C32.6209 17.2274 34.9192 14.0339 34.9192 8.60226V6.49395C34.9192 5.25583 33.9119 4.24854 32.6738 4.24854H28.4572C27.2191 4.24854 26.2118 5.25583 26.2118 6.49395V8.60226C26.2118 10.1695 25.921 11.188 25.269 11.9045C24.8131 12.4056 24.1373 12.8025 23.252 13.0904V5.31151C23.252 4.72448 22.7761 4.24854 22.189 4.24854H14.2937C12.713 4.24854 11.4271 5.53447 11.4271 7.11516C11.4271 8.44842 12.3329 9.59521 13.6299 9.90395L13.9754 9.98615C14.3105 10.0659 14.5445 10.3622 14.5445 10.7066V21.6281C13.5994 20.9807 12.7169 20.1227 11.9415 19.0907C9.85593 16.3147 8.70738 12.5899 8.70738 8.60212V6.49395C8.70738 5.25583 7.70009 4.24854 6.46196 4.24854H2.24535C1.00722 4.24854 0 5.25583 0 6.49395V8.60226C0 14.5821 1.8362 20.2483 5.17061 24.5571C8.76082 29.1964 13.636 31.7514 18.8983 31.7514H21.0066C22.2447 31.7514 23.252 30.7441 23.252 29.506V24.3338C24.6144 25.5069 25.7206 27.0976 26.549 29.0779L27.0906 30.3726C27.441 31.2101 28.2541 31.7513 29.162 31.7513H33.7537C34.5096 31.7513 35.2098 31.375 35.6269 30.7444C36.0438 30.1139 36.116 29.3223 35.82 28.6268Z" fill="#464646"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="36" height="36" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a href="" target="_blank">
                            <svg class="fb" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31 5.00033C31 2.373 28.5489 0 25.8333 0H5.16667C2.45107 0 0 2.373 0 5.00033V24.9997C0 27.627 2.45107 30 5.16701 30H15.5V18.6667H11.7111V13.6667H15.5V11.7187C15.5 8.359 18.1068 5.33333 21.3125 5.33333H25.4889V10.3333H21.3125C20.8554 10.3333 20.3222 10.8703 20.3222 11.6747V13.6667H25.4889V18.6667H20.3222V30H25.8333C28.5489 30 31 27.627 31 24.9997V5.00033Z" fill="#464646"/>
                            </svg>
                        </a>-->
                        <a @click="sendMetrika()" href="https://www.instagram.com/greenway_style/" target="_blank">
                            <svg class="ig" width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26.0924 5.55115C25.6507 5.55115 25.2913 5.87792 25.2913 6.27947C25.2913 6.68101 25.6507 7.00779 26.0924 7.00779C26.5342 7.00779 26.8936 6.68107 26.8936 6.27947C26.8936 5.87787 26.5342 5.55115 26.0924 5.55115Z" fill="#464646"/>
                                <path d="M16.4999 8.54321C12.5836 8.54321 9.39746 11.4397 9.39746 15C9.39746 18.5603 12.5836 21.4569 16.4999 21.4569C20.4163 21.4569 23.6025 18.5603 23.6025 15.0001C23.6025 11.4398 20.4163 8.54321 16.4999 8.54321Z" fill="#464646"/>
                                <path d="M23.9536 0H9.04645C4.05823 0 0 3.6893 0 8.2241V21.776C0 26.3108 4.05823 30 9.04645 30H23.9536C28.9418 30 33 26.3107 33 21.776V8.2241C33 3.6893 28.9418 0 23.9536 0ZM16.5 23.2284C11.5091 23.2284 7.44878 19.5372 7.44878 15C7.44878 10.4628 11.5091 6.77168 16.5 6.77168C21.4909 6.77168 25.5513 10.4629 25.5513 15C25.5513 19.5371 21.4909 23.2284 16.5 23.2284ZM26.0926 8.77945C24.5763 8.77945 23.3427 7.65797 23.3427 6.27949C23.3427 4.90102 24.5763 3.77947 26.0926 3.77947C27.6089 3.77947 28.8426 4.90096 28.8426 6.27943C28.8426 7.65791 27.6089 8.77945 26.0926 8.77945Z" fill="#464646"/>
                            </svg>
                        </a>
                        <a @click="sendMetrika()" href="https://teleg.run/mygreenwaynews" target="_blank">
                            <svg class="tg" width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path d="M36.2753 2.94953C35.68 2.41889 34.834 2.24205 34.0672 2.48793L1.53404 12.9224C0.647417 13.2067 0.0461669 13.9768 0.00230159 14.8841C-0.0414914 15.7914 0.482796 16.613 1.33799 16.9771L7.87117 19.7583L27.1061 9.37147C27.5759 9.11779 28.1655 9.23633 28.4936 9.64998C28.8218 10.0638 28.7899 10.6487 28.4187 11.0264L16.4271 23.226L13.9678 31.8713L19.8705 27.5709L26.8506 33.1283C27.2572 33.4522 27.7541 33.6211 28.2607 33.6211C28.5019 33.621 28.7455 33.5827 28.9824 33.5045C29.717 33.2622 30.2616 32.6788 30.4392 31.9441L36.9405 5.04928C37.1253 4.28477 36.8705 3.48018 36.2753 2.94953Z" fill="#464646"/>
                                    <path d="M9.57349 21.2573L11.8326 31.4521L14.4061 22.4056C14.454 22.2372 14.5439 22.083 14.6679 21.9569L21.8966 14.6029L9.57349 21.2573Z" fill="#464646"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="37" height="36" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a @click="sendMetrika()" href="https://www.youtube.com/channel/UCQx94ndp4A6tnqzdKCWYOYQ" target="_blank">
                            <svg class="yt" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path d="M35.4981 9.50693C35.3092 7.93058 33.8236 6.41901 32.2693 6.2165C22.7913 5.03883 13.207 5.03883 3.73198 6.2165C2.17637 6.41857 0.690813 7.93058 0.501844 9.50693C-0.167281 15.2842 -0.167281 20.8858 0.501844 26.6618C0.690813 28.2381 2.17637 29.7511 3.73198 29.9522C13.207 31.1299 22.7916 31.1299 32.2693 29.9522C33.8236 29.7514 35.3092 28.2381 35.4981 26.6618C36.1674 20.8864 36.1674 15.2842 35.4981 9.50693ZM14.9998 24.0855V12.0851L24 18.0855L14.9998 24.0855Z" fill="#464646"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="36" height="36" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <!--<a href="" target="_blank">
                            <svg class="ok" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.0163 11.9969C16.6909 11.9915 18.0246 10.6469 18.0185 8.9685C18.0118 7.2901 16.6759 5.95318 15.0007 5.95191C13.3114 5.95031 11.9624 7.31116 11.9754 9.00489C11.9879 10.6769 13.3366 12.0039 15.0163 11.9969Z" fill="#464646"/>
                                <path d="M25.9091 0H4.09091C2.04545 0 0 2.04545 0 4.09091V25.9091C0 27.9545 2.04545 30 4.09091 30H25.9091C27.9545 30 30 27.9545 30 25.9091V4.09091C30 2.04545 27.9545 0 25.9091 0ZM15.0255 2.79766C18.4401 2.80628 21.1816 5.60011 21.1647 9.05362C21.1465 12.427 18.3603 15.1615 14.9512 15.15C11.5759 15.1385 8.80819 12.3405 8.82734 8.95979C8.84745 5.54202 11.617 2.78745 15.0255 2.79766ZM21.9424 17.5762C21.1873 18.352 20.2784 18.9124 19.2686 19.3047C18.314 19.6736 17.2682 19.8606 16.2329 19.9845C16.3893 20.1549 16.463 20.2376 16.56 20.3362C17.9655 21.7484 19.3768 23.1549 20.7776 24.5713C21.2544 25.0535 21.3552 25.6519 21.0919 26.2133C20.8044 26.8277 20.1613 27.2307 19.53 27.1873C19.1304 27.1593 18.8189 26.9604 18.5426 26.6824C17.4817 25.6149 16.4007 24.5674 15.3622 23.4811C15.06 23.1645 14.9145 23.2235 14.647 23.4989C13.5801 24.5974 12.4966 25.679 11.4061 26.7543C10.9162 27.2374 10.3337 27.3239 9.76468 27.0485C9.16085 26.7559 8.77723 26.1396 8.80691 25.5195C8.82734 25.1007 9.03351 24.781 9.32106 24.4937C10.7113 23.1051 12.0983 21.713 13.486 20.3218C13.5782 20.2299 13.6637 20.1313 13.7974 19.9883C11.9052 19.7907 10.199 19.3254 8.73894 18.1829C8.55798 18.0409 8.37064 17.9036 8.20468 17.7447C7.56574 17.1306 7.5016 16.4282 8.00745 15.7034C8.43957 15.0836 9.1666 14.917 9.92138 15.2729C10.0679 15.3415 10.207 15.428 10.3404 15.5202C13.0621 17.3904 16.8016 17.4424 19.5338 15.6051C19.8041 15.3986 20.0936 15.2276 20.4294 15.1427C21.082 14.9751 21.689 15.2141 22.0395 15.7848C22.4406 16.4349 22.4352 17.0706 21.9424 17.5762Z" fill="#464646"/>
                            </svg>
                        </a>-->
                    </div>
                    <div class="law">
                        <a href="https://mygreenway.com/s/offer/" class="link" target="_blank">Публичная оферта</a><span> | </span>
                        <a href="https://mygreenway.com/s/personal/" class="link" target="_blank">Политика конфиденциальности</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            sendMetrika () {
                if (this.$metrika) {
                    this.$metrika.reachGoal('social_net')
                }
            }
        }
    }
</script>
