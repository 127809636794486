<template>
    <div class="overlay" v-if="show" @click="$emit('closePopup')">
        <div class="popup" @click.stop>
            <svg class="close" @click="$emit('closePopup')" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 17.2 17.2" style="enable-background:new 0 0 17.2 17.2;" xml:space="preserve">
                <path class="st0" d="M8.6,0.3c4.6,0,8.3,3.7,8.3,8.3c0,4.6-3.7,8.3-8.3,8.3c-4.6,0-8.3-3.7-8.3-8.3C0.3,4,4,0.3,8.6,0.3z M11.6,4.4
                    l-3,3l-3-3L4.4,5.6l3,3l-3,3l1.2,1.2l3-3l3,3l1.2-1.2l-3-3l3-3L11.6,4.4z" fill="#EFEEEE"/>
                </svg>

            <strong v-if="type=='graph'">Изучи все 4{{'\xa0'}}видео и{{'\xa0'}}узнай как легко пройти активацию!</strong>
            <strong v-if="type=='not_activated'">Чтобы изучить этот урок, тебе нужно активировать соглашение</strong>
            <strong v-if="type=='not_activated_webinar'">Чтобы попасть на{{'\xa0'}}этот вебинар, тебе нужно активировать соглашение</strong>
            <strong v-if="type=='webinar_low_lo'">Вебинар доступен от{{'\xa0'}}{{ lo }}{{'\xa0'}}PV личного объёма</strong>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['show', 'type', 'lo']
    }
</script>