<template>
    <div class="auth">
        <div class="login-panel">
            <div>
                <div class="logo">
                    <img class="logo-image" src="@/assets/images/logo-image.svg" />
                    <img class="logo-text" src="@/assets/images/logo-text.svg" />
                </div>

                <strong>Войти</strong>
                <span>в{{'\xa0'}}личный офис</span>

                <div :class="{disabled: status == 'loading'}">
                    <input type="text" class="text-input" placeholder="ID / Телефон" v-model="username" />
                    <input type="password" class="text-input" placeholder="Пароль" v-model="password" />

                    <p class="error" :class="{_show: show_error}">{{ error_msg }}</p>
                </div>
                <div class="button" :status="status" @click="login()"><div>Войти</div></div>


                <a class="link _reverse" target="_blank" href="https://mygreenway.com/s/p/">Забыли пароль?</a>
            </div>
        </div>
    </div>
</template>

<script>
    import * as utils from '@/utils/auth'
    export default {
        metaInfo: {
            title: 'Greenway Prof.'
        },
        data () {
            return {
                status: '',
                username: '',
                password: '',
                show_error: false,
                show_error_tm: false,
                error_msg: ''
            }
        },
        methods: {
            showError (msg) {
                this.error_msg = msg
                this.show_error = true
                if (this.show_error_tm) clearTimeout(this.show_error_tm)
                this.show_error_tm = setTimeout(() => {
                    this.show_error = false
                    this.error_msg = ''
                }, 5000)
            },
            login () {
                if (this.$metrika) {
                    this.$metrika.reachGoal('login')
                }

                this.show_error = false
                this.status = 'loading'
                this.$store.commit('auth/auth_request')
                let sendData = {
                    login: this.username,
                    password: this.password
                }
                this.axios({url: 'api/authenticate', data: sendData, method: 'POST' })
                    .then(resp => {
                        this.status = ''
                        this.$store.commit('auth/auth_success', resp.data)
                        this.$router.push('/')
                    }).catch(err => {
                        this.status = ''

                        if (err.response.data.detail) {
                            this.showError(err.response.data.detail)
                        }
                        this.$store.commit('auth/auth_error')
                    })
            },
            authByToken () {
                let storage_token = utils.getCookie(true)
                if (storage_token) {
                    let sendData = {
                        refresh: storage_token
                    }
                    this.axios({url: 'api/refresh', data: sendData, method: 'POST'})
                        .then(resp => {
                            this.$store.commit('auth/auth_success', resp.data)
                            this.$router.push('/')
                        })
                }
            }
        },
        created () {
            this.authByToken()
        }
    }
</script>