import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import Trainer from '../views/Trainer.vue'
import CourseList from '../views/CourseList.vue'
import WebinarList from '../views/WebinarList.vue'
import Lesson from '../views/Lesson.vue'
import PageNotFound from '../views/PageNotFound.vue'

import store from './../store'
import axios from 'axios'

import * as utils from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'main',
        meta: {
            requiresAuth: true
        },
        component: Main
    },
    {
        path: '/trainer',
        name: 'trainer',
        meta: {
            requiresAuth: true
        },
        component: Trainer
    },
    {
        path: '/courses',
        name: 'courses',
        meta: {
            requiresAuth: true
        },
        component: CourseList
    },
    {
        path: '/webinars',
        name: 'webinars',
        meta: {
            requiresAuth: true
        },
        component: WebinarList
    },
    {
        path: '/lesson/:id',
        name: 'lesson',
        meta: {
           requiresAuth: true
        },
        component: Lesson
    },
    {
        path: "*",
        component: PageNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/isLoggedIn']) {
            next()
        } else {
            authByToken().then(() => {
                next()
            }).catch(() => {
                next('/login')
                /*if (location.href.indexOf('https://prof.mygreenway.com') != -1) {
                    location.href = 'https://mygreenway.com/my/?redirect=' + location.href
                } else {
                    location.href = 'https://dev1-2020.greenwaystart.com/my/?redirect=' + location.href
                }*/
            })
        }
    } else {
        if (to.name == 'login') {
            if (store.getters['auth/isLoggedIn']) {
                next('/')
            } else {
                next()
            }
        } else {
            next()
        }
    }
})

function authByToken () {
    return new Promise((resolve, reject) => {
        let storage_token = utils.getCookie(true)
        if (storage_token) {
            let sendData = {
                refresh: storage_token
            }
            axios({url: 'api/refresh', data: sendData, method: 'POST'})
                .then(resp => {
                    store.commit('auth/auth_success', resp.data)
                    resolve()
                }).catch(() => {
                    reject()
                })
        } else {
            reject()
        }
    })
}

export default router
