<template>
    <div class="page">
        <div class="page-content">
            <Header></Header>
            <div class="course-list">
                <div class="breadcrumbs">
                    <div class="wrapper">
                        <router-link class="link" to="/">Главная</router-link>
                        <span>Курсы</span>
                    </div>
                </div>

                <div class="content-wrapper">
                    <strong v-if="status == 'loading'" class="loading">Загрузка...</strong>

                    <h1 v-else v-html="page.title"></h1>
                </div>

                <div class="carousel-block" :course_id="item.id" v-for="(item, key) in courses" :key="key">
                    <div class="content-wrapper">
                        <h2 v-html="item.title"></h2>
                    </div>
                    <LessonsCarousel page="courses" block="true" :not_activated="checkNotActivated(item.id)" :course="item" :lessons="item.lessons" :description="item.description"></LessonsCarousel>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <ActivatedPopup type="not_activated" :show="show_popup" @closePopup="show_popup = false"></ActivatedPopup>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import LessonsCarousel from '@/components/LessonsCarousel.vue'
    import ActivatedPopup from '@/components/ActivatedPopup.vue'
    import Footer from '@/components/Footer.vue'

    import * as utils from '@/utils/auth'

    export default {
        metaInfo: {
            title: 'Список курсов'
        },
        components: {
            Header,
            LessonsCarousel,
            ActivatedPopup,
            Footer
        },
        data () {
            return {
                status: '',
                page: {},
                courses: [],
                show_popup: false
            }
        },
        methods: {
            checkNotActivated (id) {
                if (id == 1) return false
                return !this.$store.getters['auth/userActivated']
            },
            getData () {
                this.status = 'loading'

                let pyapi_refresh_token = utils.getCookie()

                this.axios({url: 'api/courses?types=lesson&pyapi_refresh_token=' + pyapi_refresh_token, method: 'GET' })
                    .then(resp => {
                        this.status = ''

                        this.page = resp.data.page
                        this.courses = resp.data.courses

                        let data = {
                            user_info: resp.data.user_info,
                            token_info: resp.data.token_info
                        }
                        this.$store.commit('auth/auth_success', data)

                        this.$nextTick(() => {
                            this.scrollToCourse()
                        })

                    }).catch(() => {
                        this.status = ''
                        // this.$router.push('/')
                    })
            },
            scrollToCourse () {
                if (this.$route.query.course_id) {
                    let id = this.$route.query.course_id
                    let scrollToElem = document.querySelectorAll("[course_id='"+id+"']")[0];
                    if (scrollToElem) {
                        window.scrollTo({
                            top: scrollToElem.offsetTop - 20,
                            behavior: "smooth"
                        })
                    }
                    this.$router.replace({'query': null});
                }
            }
        },
        created () {
            this.getData()
        },
        mounted () {
            if (this.$route.query.not_activated == 1) {
                this.show_popup = true
                this.$router.replace({'query': null});
            }
        }
    }
</script>